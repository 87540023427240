import React from 'react'
import { Text, AvatarSize } from '@revolut/ui-kit'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { IdStatuses, AvatarType } from '@src/interfaces/employees'
import { useIsNewTable } from '@components/TableV2/hooks'

interface EmployeeCellProps {
  employee?: {
    id: number
    name?: string
    full_name?: string
    avatar?: AvatarType
    status?: IdStatuses
  } | null
  size?: AvatarSize
  compact?: boolean
}

export const EmployeeCell = ({ employee, size, compact }: EmployeeCellProps) => {
  const isNewTable = useIsNewTable()

  if (employee?.full_name === 'Pending Employee') {
    return <Text style={{ fontStyle: 'italic' }}>{employee.full_name}</Text>
  }

  return (
    <UserWithAvatar
      {...employee}
      size={isNewTable ? size : undefined}
      compact={compact}
    />
  )
}
