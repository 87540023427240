import { useParams } from 'react-router'
import { ReviewCyclesInterface, ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import React from 'react'
import { useTriggerCycleSync } from '@src/api/supportTool/eligibleEmployees'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { Button, MoreBar, useStatusPopup } from '@revolut/ui-kit'

interface Props {
  cycle: ReviewCyclesInterface
}

export const EligibleGroupActions = ({ cycle }: Props) => {
  const params = useParams<{ id: string }>()
  const { mutateAsync: triggerCycleSync, isLoading } = useTriggerCycleSync()
  const showStatusPopup = useShowStatusPopup()
  const { hide } = useStatusPopup()

  const hasActions = cycle.status === ReviewCycleStatus.ongoing

  const handleSync = async () => {
    try {
      await triggerCycleSync({ cycle_id: params.id })
      showStatusPopup({ title: 'Employee data synchronised.' })
    } catch (_error) {
      showStatusPopup({
        status: 'error',
        title: 'Failed to synchronize employee data.',
        actions: <Button onClick={hide}>Go back</Button>,
      })
    }
  }

  if (!hasActions) {
    return null
  }

  return (
    <MoreBar>
      <MoreBar.Action useIcon="ArrowExchange" onClick={handleSync} pending={isLoading}>
        Sync eligibility now
      </MoreBar.Action>
    </MoreBar>
  )
}
