import { Box, Flex, Text, TextSkeleton, Token } from '@revolut/ui-kit'
import pluralize from 'pluralize'
import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import SortableList from '@components/SortableList/SortableList'
import { SelectAllCell } from '@components/TableV2/AdvancedCells/SelectCell/SelectCell'
import { ColumnsSettingsButton } from '@components/TableV2/Buttons/ColumnsSettingsButton'
import { ActionsCell } from '@components/TableV2/Cells/ActionsCell'
import { EmployeeCell } from '@components/TableV2/Cells/EmployeeCell'
import { EntityCell } from '@components/TableV2/Cells/EntityCell'
import { ItemCell } from '@components/TableV2/Cells/ItemCell'
import { ProgressCell } from '@components/TableV2/Cells/ProgressCell'
import { SeniorityCell } from '@components/TableV2/Cells/SeniorityCell'
import { StatusCell } from '@components/TableV2/Cells/StatusCell'
import { RowSeparator } from '@components/TableV2/RowSeparator'
import SearchTable from '@components/TableV2/SearchTable/SearchTable'
import TableLoader from '@components/TableV2/TableLoader'
import TableProvider, {
  useTableContext,
} from '@components/TableV2/TableProvider/TableProvider'
import TableSettings from '@components/TableV2/TableSettings'
import { ADJUSTABLE_TABLE_PADDING } from '@components/TableV2/constants'
import { getCellsBasedOnSettings } from '@components/TableV2/utils'
import VirtualList from '@components/VirtualList/VirtualList'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { FormError } from '@src/features/Form/LapeForm'
import { ColumnCellInterface, RowInterface, Stats } from '@src/interfaces/data'
import { RowHeight, TableTypes } from '@src/interfaces/table'
import { TableSettingsInterface } from '@src/interfaces/tableSettings'
import { defaultTheme } from '@src/styles/theme'
import { bulkNormalizeWidth, generateKey } from '@src/utils/table'
import { Virtuoso } from 'react-virtuoso'
import { cellWrapperRole } from './Cell'
import { CountryCell } from './Cells/CountryCell'
import HeaderCell, { HEADER_CELL_WRAPPER, HeaderCellWrapper } from './HeaderCell'
import Row from './Row'
import { useStoredTableSettings, useTableReturnType } from './hooks'

export interface TableProps<T, S> extends Partial<useTableReturnType<T, S>> {
  name: TableNames
  onRowClick?: (data: T, parentIndexes: number[]) => void
  row: RowInterface<T>
  noDataMessage?: React.ReactNode
  type?: TableTypes
  count: number
  data: T[]
  noAutoResize?: boolean
  cellErrors?: (FormError<T> | undefined)[]
  stickyHeaderTop?: number
  noReset?: boolean
  width?: number
  useWindowScroll?: boolean
  hiddenCells?: { [key in string]?: boolean }
  orderingMode?: boolean
  onChangeOrder?: (
    ids: (number | string)[],
    activeIndex: number,
    targetIndex: number,
  ) => void
  setSelectedOrderingIds?: (ids: (number | string)[]) => void
  selectedOrderingIds?: (number | string)[]
  activeOrderingRow?: number | null
  idPath?: string
  disabledFiltering?: boolean
  disabledSorting?: boolean
  renderSeparatorContent?: (data: T, index: number) => React.ReactNode
  childrenAlwaysOpen?: boolean
  childrenOpenByDefault?: boolean
  emptyState?: React.ReactNode
  expandableType?: 'chevron' | 'plus'
  /** Locks first column in place and makes text slightly bolder */
  lockFirstColumn?: boolean
  lockLastColumn?: boolean
  dataType?: string
  hideHeader?: boolean
  hideCount?: boolean
  /** @deprecated */
  hideCountAndButtonSection?: boolean
  /** @deprecated */
  rowHeight?: RowHeight
  selectedCount?: number
  pendingDataType?: boolean
  enableSettings?: boolean
  tableSettings?: TableSettingsInterface
  renderCount?: (count: number, selectedCount?: number) => React.ReactNode
  useFetchedChildren?: boolean
  mainColumnIndex?: number
}

const MatrixTableCss = css`
  border: none;
`

const AdjustableCss = css`
  min-height: auto;
  border: none;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
`

const ContainedCss = css`
  min-height: auto;
  border-bottom: 1px solid ${Token.color.greyTone5};
  border-right: 1px solid ${Token.color.greyTone5};
  border-left: 1px solid ${Token.color.greyTone5};
`

const HeaderWithBorderCss = css`
  border-radius: ${Token.radius.r16} ${Token.radius.r16} 0 0;
  border-top: 1px solid ${Token.color.greyTone5};
  border-right: 1px solid ${Token.color.greyTone5};
  border-left: 1px solid ${Token.color.greyTone5};
`

export const SHOW_LEFT_SHADOW_ATTR = 'data-show-left-shadow'
export const SHOW_RIGHT_SHADOW_ATTR = 'data-show-right-shadow'
export const SHOW_LEFT_LOCK_SHADOW_ATTR = 'data-show-left-lock-shadow'
export const SHOW_RIGHT_LOCK_SHADOW_ATTR = 'data-show-right-lock-shadow'
export const LOCK_FIRST_COLUMN_ATTR = 'data-lock-first-column'
export const LOCK_LAST_COLUMN_ATTR = 'data-lock-last-column'

const getShadowStyle = (direction: 'left' | 'right') => css`
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  ${direction}: 0;
  width: 24px;
  background: linear-gradient(
    ${direction === 'left' ? '270deg' : '90deg'},
    rgba(0, 0, 0, 0) 0%,
    rgba(25, 28, 31, 0.02) 50%,
    rgba(25, 28, 31, 0.04) 75%,
    rgba(25, 28, 31, 0.0625) 100%
  );
  pointer-events: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: ${defaultTheme.zIndex.aboveMain + 2};
`

const TableContainer = styled.div<{ type: TableTypes; filtering?: boolean }>`
  font-size: 14px;
  overflow-x: auto;
  position: relative;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: ${Token.radius.widget};
  border-bottom-right-radius: ${Token.radius.widget};
  ${({ type }) => type === TableTypes.Contained && ContainedCss};
  ${({ type }) => type === TableTypes.Adjustable && AdjustableCss};
  ${({ type }) => type === TableTypes.Matrix && MatrixTableCss};
  ${({ filtering }) =>
    filtering &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`

const NoDataRow = styled.div`
  min-height: 41px;
`
const NoData = styled.div`
  color: ${Token.color.greyTone50};
  padding: 10px 8px 10px 16px;
  width: 100%;
`

const TableButtons = styled(Flex)`
  opacity: 0;
  gap: 8px;
  z-index: ${defaultTheme.zIndex.aboveMain + 1};
  margin-left: auto;
`

const Header = styled.div<{
  useWindowScroll?: boolean
  top?: number
  width?: number
  hasBorder?: boolean
}>`
  width: ${({ width }) => (width ? `${width}px` : undefined)};
  display: flex;
  z-index: ${defaultTheme.zIndex.aboveMain + 2};
  position: ${({ useWindowScroll }) => (useWindowScroll ? 'sticky' : 'relative')};
  top: ${({ top }) => top ?? 0}px;
  background: ${Token.color.groupedBackground};
  flex: 0 0 auto;
  overflow-x: auto;
  border-top-left-radius: ${Token.radius.widget};
  border-top-right-radius: ${Token.radius.widget};

  -ms-overflow-style: none;
  scrollbar-width: none;

  ${({ hasBorder }) => hasBorder && HeaderWithBorderCss};

  &::-webkit-scrollbar {
    display: none;
  }
`

const HeaderCellSelector = `.${HEADER_CELL_WRAPPER}`

const WrapperCss = css`
  isolation: isolate;
  font-family: ${Token.font.brand};

  &:hover ${TableButtons} {
    opacity: 1;
  }
`

const TableWrapperCss = css`
  position: relative;

  &[${SHOW_LEFT_SHADOW_ATTR}] {
    &::before {
      ${getShadowStyle('left')}
    }
  }
  &[${SHOW_RIGHT_SHADOW_ATTR}] {
    &::after {
      ${getShadowStyle('right')}
    }
  }

  &[${LOCK_FIRST_COLUMN_ATTR}=true] [role=${cellWrapperRole}]:first-child,
  &[${LOCK_FIRST_COLUMN_ATTR}=true] ${HeaderCellSelector}:first-child {
    font-weight: 500;
    font-size: 16px;
    position: sticky;
    left: 0;
    z-index: ${defaultTheme.zIndex.aboveMain + 1};
  }

  &[${LOCK_FIRST_COLUMN_ATTR}=true] ${HeaderCellSelector}:first-child {
    font-size: 14px;
  }

  &[${LOCK_LAST_COLUMN_ATTR}=true] [role=${cellWrapperRole}]:last-child,
  &[${LOCK_LAST_COLUMN_ATTR}=true] ${HeaderCellSelector}:last-child {
    position: sticky;
    right: 0;
    z-index: ${defaultTheme.zIndex.aboveMain + 1};
  }

  &[${SHOW_LEFT_LOCK_SHADOW_ATTR}] [role=${cellWrapperRole}]:first-child::after,
  &[${SHOW_LEFT_LOCK_SHADOW_ATTR}] ${HeaderCellSelector}:first-child::after {
    ${getShadowStyle('left')}
    right: -24px;
    left: unset;
    border-radius: 0;
  }

  &[${SHOW_RIGHT_LOCK_SHADOW_ATTR}] [role=${cellWrapperRole}]:last-child::before,
  &[${SHOW_RIGHT_LOCK_SHADOW_ATTR}] ${HeaderCellSelector}:last-child::before {
    ${getShadowStyle('right')}
    left: -24px;
    border-radius: 0;
  }
`

const TableWrapperNoFirstColumnHighlightCss = css`
  ${TableWrapperCss}
  &[${LOCK_FIRST_COLUMN_ATTR}=true] [role=${cellWrapperRole}]:first-child,
  &[${LOCK_FIRST_COLUMN_ATTR}=true] ${HeaderCellSelector}:first-child {
    font-weight: inherit;
    font-size: inherit;
  }
`

const rowHeightToWidthMultiplier = {
  small: 0.7,
  medium: 0.85,
  large: 1,
}

const Table = <T extends { [prop: string]: any; id?: number | string }, S = Stats>({
  name,
  loading,
  nextPageLoading,
  data,
  row,
  onSortChange,
  onFilterChange,
  fetchSelectors,
  onRowClick,
  useWindowScroll,
  stickyHeaderTop,
  sortBy,
  filterBy,
  fetchNextPage,
  fetchChildren,
  noDataMessage,
  type = TableTypes.Form,
  width,
  noAutoResize,
  cellErrors,
  count,
  hiddenCells,
  orderingMode,
  onChangeOrder,
  setSelectedOrderingIds,
  selectedOrderingIds,
  activeOrderingRow,
  idPath = 'id',
  disabledFiltering,
  disabledSorting,
  renderSeparatorContent,
  childrenAlwaysOpen = false,
  emptyState,
  expandableType,
  lockFirstColumn = true,
  lockLastColumn = false,
  dataType = 'Item',
  pendingDataType = false,
  hideHeader,
  hideCount = true,
  hideCountAndButtonSection,
  selectedCount,
  enableSettings = true,
  tableSettings: initialTableSettings,
  renderCount,
  useFetchedChildren = false,
  childrenOpenByDefault = false,
  mainColumnIndex = 0,
}: TableProps<T, S>) => {
  // used to trigger re-render by children
  const [, setForceRender] = useState(0)
  const tableContext = useTableContext()
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const tableWrapper = useRef<HTMLDivElement | null>(null)
  const headerWrapper = useRef<HTMLDivElement | null>(null)
  const rowHeight = 'large'
  let ignoreHeaderScroll = false
  let ignoreTableScroll = false

  const handleTableShadows = () =>
    requestAnimationFrame(() => {
      if (tableWrapper.current) {
        const scroll = tableWrapper.current.scrollLeft

        if (wrapperRef.current) {
          const leftShadowAttr = lockFirstColumn
            ? SHOW_LEFT_LOCK_SHADOW_ATTR
            : SHOW_LEFT_SHADOW_ATTR

          if (scroll > 0) {
            wrapperRef.current.setAttribute(leftShadowAttr, 'true')
          } else {
            wrapperRef.current.removeAttribute(leftShadowAttr)
          }

          const wrapperWidth = wrapperRef?.current?.clientWidth
          const headerWidth = headerWrapper?.current?.scrollWidth

          if (
            headerWidth &&
            wrapperWidth < headerWidth &&
            scroll < headerWidth - wrapperWidth
          ) {
            wrapperRef.current.setAttribute(SHOW_RIGHT_SHADOW_ATTR, 'true')
          } else {
            wrapperRef.current.removeAttribute(SHOW_RIGHT_SHADOW_ATTR)
          }

          if (lockLastColumn && headerWidth) {
            const rightShadowAttr = lockLastColumn
              ? SHOW_RIGHT_LOCK_SHADOW_ATTR
              : SHOW_RIGHT_SHADOW_ATTR

            if (wrapperWidth < headerWidth && scroll < headerWidth - wrapperWidth) {
              wrapperRef.current.setAttribute(rightShadowAttr, 'true')
              wrapperRef.current.removeAttribute(SHOW_RIGHT_SHADOW_ATTR)
            } else {
              wrapperRef.current.removeAttribute(rightShadowAttr)
            }
          }
        }
      }
    })

  const handleTableScroll = () => {
    const ignore = ignoreTableScroll
    ignoreTableScroll = false
    if (headerWrapper.current && tableWrapper.current && !ignore) {
      ignoreHeaderScroll = true
      headerWrapper.current.scrollLeft = tableWrapper.current.scrollLeft
      handleTableShadows()
    }
  }

  const handleHeaderScroll = () => {
    const ignore = ignoreHeaderScroll
    ignoreHeaderScroll = false
    if (headerWrapper.current && tableWrapper.current && !ignore) {
      ignoreTableScroll = true
      tableWrapper.current.scrollLeft = headerWrapper.current.scrollLeft
      handleTableShadows()
    }
  }

  useEffect(() => {
    if (tableWrapper?.current && headerWrapper?.current && !loading) {
      tableWrapper.current.addEventListener('scroll', handleTableScroll)
      headerWrapper.current.addEventListener('scroll', handleHeaderScroll)
      handleTableShadows()
    }
    return () => {
      tableWrapper.current?.removeEventListener('scroll', handleTableScroll)
      headerWrapper.current?.removeEventListener('scroll', handleHeaderScroll)
    }
  }, [tableWrapper, headerWrapper, loading])

  const filteredCells = row.cells.filter(cell => !hiddenCells?.[cell.idPoint])
  const storedTableSettings = useStoredTableSettings(name, initialTableSettings)
  const [tableSettings, setTableSettings] = useState(storedTableSettings)

  useEffect(() => {
    setTableSettings(storedTableSettings)
  }, [storedTableSettings])

  const columnSettings = getCellsBasedOnSettings(
    filteredCells,
    tableSettings,
    !enableSettings,
  )

  if (!row?.cells) {
    return <InternalRedirect to={ROUTES.MAIN} />
  }

  const forceRender = () => {
    setForceRender(Math.random())
  }

  const visibleCellsAdjustedWidths = noAutoResize
    ? columnSettings.visible
    : (bulkNormalizeWidth(
        columnSettings.visible.map(cell => ({
          ...cell,
          width: cell.width * rowHeightToWidthMultiplier[rowHeight],
        })),
        type,
        width,
        lockFirstColumn,
        true,
      ) as ColumnCellInterface<T>[])

  const changeColumn = (idPoint: string, propName: string, value: any) => {
    const column = row.cells.find(r => r.idPoint === idPoint)
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    column![propName] = value
    forceRender()
  }

  const renderList = () => {
    if (orderingMode && onChangeOrder && selectedOrderingIds) {
      return (
        <SortableList<T>
          count={count}
          onLoadMore={fetchNextPage}
          onRowClick={onRowClick}
          key={`${filterBy?.map(fil => fil.columnName).join()}${sortBy
            ?.map(sort => sort.sortBy + sort.direction)
            .join()}`}
          data={data}
          renderItem={(index, style) => {
            const rowData = data[index]
            if (rowData.isSeparator) {
              return renderSeparatorContent ? (
                <RowSeparator
                  key={`row_${generateKey(data[index], idPath)}`}
                  rowHeight={rowHeight}
                  rowWidth={width}
                  separatorContent={renderSeparatorContent(rowData, index)}
                />
              ) : null
            }
            return (
              <Row<T>
                key={`row_${generateKey(data[index], idPath)}`}
                data={data[index]}
                row={row}
                cellErrors={cellErrors}
                forceRender={forceRender}
                onRowClick={onRowClick}
                visibleCells={visibleCellsAdjustedWidths}
                noChildrenRequest={row?.noChildrenRequest}
                onFilterChange={onFilterChange}
                fetchChildren={fetchChildren}
                parentIndexes={[index]}
                type={type}
                style={style}
                idPath={idPath}
                childrenAlwaysOpen={childrenAlwaysOpen}
                childrenOpenByDefault={childrenOpenByDefault}
                expandableType={expandableType}
                containerWidth={tableWrapper.current?.scrollWidth}
                rowHeight={rowHeight}
                alterBackground={hideHeader}
              />
            )
          }}
          onUpdate={onChangeOrder}
          selectedIds={selectedOrderingIds}
          onChangeSelected={setSelectedOrderingIds}
          activeOrderingRow={activeOrderingRow}
          useWindowScroll={useWindowScroll}
        />
      )
    }

    if (useWindowScroll) {
      return (
        <Virtuoso<T>
          useWindowScroll
          endReached={fetchNextPage}
          data={data}
          overscan={1000}
          itemContent={(index, rowData) => {
            if (rowData.isSeparator) {
              return renderSeparatorContent ? (
                <RowSeparator
                  key={`row_${generateKey(rowData, idPath)}`}
                  rowHeight={rowHeight}
                  rowWidth={width}
                  separatorContent={renderSeparatorContent(rowData, index)}
                />
              ) : null
            }
            return (
              <Row<T>
                key={`row_${generateKey(rowData, idPath)}`}
                data={rowData}
                row={row}
                cellErrors={cellErrors}
                forceRender={forceRender}
                visibleCells={visibleCellsAdjustedWidths}
                onRowClick={onRowClick}
                noChildrenRequest={row?.noChildrenRequest}
                onFilterChange={onFilterChange}
                fetchChildren={fetchChildren}
                parentIndexes={[index]}
                type={type}
                idPath={idPath}
                childrenAlwaysOpen={childrenAlwaysOpen}
                childrenOpenByDefault={childrenOpenByDefault}
                expandableType={expandableType}
                containerWidth={tableWrapper.current?.scrollWidth}
                rowHeight={rowHeight}
                useFetchedChildren={useFetchedChildren}
                alterBackground={hideHeader}
              />
            )
          }}
        />
      )
    }

    return (
      <VirtualList
        count={count}
        onLoadMore={fetchNextPage}
        key={`${filterBy?.map(fil => fil.columnName).join()}${sortBy
          ?.map(sort => sort.sortBy + sort.direction)
          .join()}`}
      >
        {data.map((rowData, rowIndex) => (
          <Row<T>
            key={`row_${generateKey(rowData, idPath)}`}
            data={rowData}
            row={row}
            cellErrors={cellErrors}
            forceRender={forceRender}
            visibleCells={visibleCellsAdjustedWidths}
            onRowClick={onRowClick}
            noChildrenRequest={row?.noChildrenRequest}
            onFilterChange={onFilterChange}
            fetchChildren={fetchChildren}
            parentIndexes={[rowIndex]}
            type={type}
            idPath={idPath}
            childrenAlwaysOpen={childrenAlwaysOpen}
            childrenOpenByDefault={childrenOpenByDefault}
            expandableType={expandableType}
            containerWidth={tableWrapper.current?.scrollWidth}
            rowHeight={rowHeight}
            useFetchedChildren={useFetchedChildren}
            alterBackground={hideHeader}
          />
        ))}
      </VirtualList>
    )
  }

  const renderTableBody = () => {
    if (data?.length) {
      return renderList()
    }

    if (loading) {
      return null
    }

    if (emptyState) {
      return emptyState
    }

    return (
      <NoDataRow>
        <NoData>{noDataMessage || 'No data'}</NoData>
      </NoDataRow>
    )
  }

  const countLabel = pendingDataType ? (
    <TextSkeleton width="200px" />
  ) : renderCount ? (
    <Box pl="s-16">{renderCount(count, selectedCount)}</Box>
  ) : (
    <Text color={Token.color.greyTone50} pl="s-16">
      Showing {count} {pluralize(dataType, count)}
      {selectedCount
        ? ` - selected ${selectedCount} ${pluralize(dataType, selectedCount)}`
        : ''}
    </Text>
  )

  return (
    <>
      <Box css={WrapperCss}>
        {hideCount || hideCountAndButtonSection ? null : (
          <Flex mb="s-8">{countLabel}</Flex>
        )}

        <Box
          width="100%"
          css={
            mainColumnIndex === 0
              ? TableWrapperCss
              : TableWrapperNoFirstColumnHighlightCss
          }
          ref={wrapperRef}
          {...{
            [LOCK_FIRST_COLUMN_ATTR]: lockFirstColumn,
            [LOCK_LAST_COLUMN_ATTR]: lockLastColumn,
          }}
          padding={type === TableTypes.Adjustable ? { all: '0 2px 2px 2px' } : undefined}
        >
          {!hideHeader && (
            <Header
              width={
                type === TableTypes.Adjustable && width
                  ? width - ADJUSTABLE_TABLE_PADDING
                  : width
              }
              ref={headerWrapper}
              useWindowScroll={useWindowScroll}
              top={stickyHeaderTop}
              hasBorder={type === TableTypes.Contained}
            >
              {visibleCellsAdjustedWidths.map((cell, index) => {
                const sort = sortBy?.find(sor => sor.sortBy === cell.sortKey)
                const filter = filterBy?.find(fil => fil.columnName === cell.filterKey)

                if (cell.renderCustomHeader) {
                  return (
                    <HeaderCellWrapper
                      type={type}
                      width={cell.width}
                      rowHeight={rowHeight}
                      key={cell.title + index}
                    >
                      {cell.renderCustomHeader()}
                    </HeaderCellWrapper>
                  )
                }
                if (cell.headerType === 'select_all') {
                  return (
                    <HeaderCellWrapper
                      type={type}
                      width={cell.width}
                      rowHeight={rowHeight}
                      key={cell.title + index}
                    >
                      <Flex
                        alignItems="center"
                        width="100%"
                        pl="s-12"
                        backgroundColor={Token.color.widgetBackground}
                      >
                        <SelectAllCell />
                      </Flex>
                    </HeaderCellWrapper>
                  )
                }
                return (
                  <HeaderCell
                    key={cell.title + index}
                    cell={cell}
                    noResize={cell.noResize}
                    fetchSelectors={fetchSelectors}
                    onSortChange={onSortChange}
                    sortBy={sort && sort.direction}
                    onFilterChange={onFilterChange}
                    filterBy={filter && filter.filters}
                    changeColumn={changeColumn}
                    headerAlign={cell.headerAlign}
                    type={type}
                    disabled={disabledFiltering}
                    disabledSorting={disabledSorting}
                    rowHeight={rowHeight}
                    count={index === mainColumnIndex ? count : undefined}
                  />
                )
              })}
            </Header>
          )}
          <TableContainer
            filtering={loading && !nextPageLoading}
            type={type}
            ref={tableWrapper}
          >
            {renderTableBody()}

            <TableLoader
              loading={loading}
              containerWidth={tableWrapper.current?.scrollWidth}
              rowHeight={rowHeight}
            />
          </TableContainer>
        </Box>
      </Box>
      {tableContext.settingsOpen ? (
        <TableSettings
          name={name}
          isOpen
          onSave={settings => setTableSettings(settings || initialTableSettings)}
          columnSettings={columnSettings}
        />
      ) : null}
    </>
  )
}

Table.ActionsCell = ActionsCell
Table.ItemCell = ItemCell
Table.EmployeeCell = EmployeeCell
Table.StatusCell = StatusCell
Table.ProgressCell = ProgressCell
Table.SeniorityCell = SeniorityCell
Table.EntityCell = EntityCell
Table.CountryCell = CountryCell
Table.ColumnsSettingsButton = ColumnsSettingsButton
Table.Widget = TableProvider
Table.Search = SearchTable

export default Table
